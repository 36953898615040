import React from 'react';
import './styles/tailwind.generated.css';
import Homepage from './pages/Homepage';
// import About from './pages/About';

function App() {
  return (
    <div className='w-full '>
      <Homepage />
      {/* <About /> */}
    </div>
  );
}

export default App;
